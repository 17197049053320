import BarkadaMarketHome from './layouts/barkadamarket.jsx';
import PluxSixThreeHome from './layouts/plussixthree.jsx';

export default function MarketplaceHome(){

  if (import.meta.env.VITE_APP_SLUG === "barkadamarket") {
    return <BarkadaMarketHome />
  } else if (import.meta.env.VITE_APP_SLUG === "plussixthree") {
    return <PluxSixThreeHome />
  }

  return null
}