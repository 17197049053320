import { useStore } from '../context/store.context';
import {FlexboxGrid as View, AutoComplete, Col, Form, Button, InputGroup} from 'rsuite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
// import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useEffect } from 'react';

export default function BarkadaMarketCover({src, alt}){
  const { store } = useStore()
  // const {
  //   placesService,
  //   placePredictions,
  //   getPlacePredictions,
  //   isPlacePredictionsLoading,
  // } = usePlacesService({
  //   apiKey: import.meta.env.VITE_APP_REACT_APP_GOOGLE,
  // });

  // useEffect(() => {
  //   console.log(placePredictions)
  //   if (placePredictions.length)
  //     placesService?.getDetails(
  //       {
  //         placeId: placePredictions[0].place_id,
  //       },
  //       (placeDetails) => console.log(placeDetails)
  //     );
  // }, [placePredictions]);

  return (
    <div className='mp-hero'>
      <View justify='center' align='middle' style={{ height: '100%' }}>
        <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} style={{ maxWidth: 1120 }}>
          <h1>Uniting Filipino Enterprises with New Zealand's Marketplace</h1>
          <Form className='mp-searcharea-wrap display-none' fluid>
            <View>
              <View.Item as={Col} lg={20} md={20} sm={24} xs={24} className='m-b-20'>
                <Form.Group controlId={'search-1'}>
                  <InputGroup inside>
                    <InputGroup.Addon>
                      <FontAwesomeIcon icon={faLocationDot}/>
                      {/* <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: 80, marginBlock: 30, color: '#81C784' }}/> */}
                    </InputGroup.Addon>
                    {/* <AutoComplete
                      className='mp-seach-field'
                      placeholder='Search for area, street name, landmark...'
                      name="search-1"
                      type="text"
                      autoComplete="off"
                      onChange={(value) => {
                        getPlacePredictions({ input: value })
                      }}
                      loading={isPlacePredictionsLoading}
                    /> */}
                    {/* <AutoComplete
                      loading={isPlacePredictionsLoading}
                      data={placePredictions}
                      className='mp-seach-field'
                      placeholder='Search for area, street name, landmark...'
                      style={{ width: 224 }}
                      onChange={(value) => getPlacePredictions(value)}
                      renderMenuItem={item => {
                        return (
                          <div>
                            {item}
                          </div>
                        );
                      }}
                    /> */}
                  </InputGroup>
                </Form.Group>
              </View.Item>
              <View.Item as={Col} lg={4} md={4} sm={24} xs={24} className='m-b-20'>
                <Button className='fw-600' href='/merchants/search' appearance="primary">Let&apos;s Go</Button>
              </View.Item>
            </View>
          </Form>
        </View.Item>
      </View>
    </div>
  );
}