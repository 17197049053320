import { Link } from "react-router-dom";
import { Panel, Stack } from "rsuite";

export default function PanelTile({
  title, description, href, linkText, img
}){
  if (!href) {
    return(
      <Panel bordered bodyFill>
        <Stack justifyContent='flex-start'>
          <Stack.Item className='img-placeholder'>
            <img src={img} alt="logo" width={120} height={130}  />
          </Stack.Item>
          <div className='panel-tile-desc'>
            <h5>{title}</h5>
            <small>{description}</small>
            {linkText && <span className='link-placeholder'>{linkText}</span>}
          </div>
        </Stack>
      </Panel>
    )
  }
  return(
    <Link to={href}>
      <Panel bordered bodyFill>
        <Stack justifyContent='flex-start'>
          <Stack.Item className='img-placeholder'>
            <img src={img} alt="logo" width={120} height={130}  />
          </Stack.Item>
          <div className='panel-tile-desc'>
            <h5>{title}</h5>
            <small>{description}</small>
            {linkText && <span className='link-placeholder'>{linkText}</span>}
          </div>
        </Stack>
      </Panel>
    </Link>
  )
}