import { FlexboxGrid as View, Content, Col, Form, InputGroup, Button, Panel, Grid, Row, Stack, Divider, Loader, Pagination } from 'rsuite';
import '../../assets/search-panel.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

import partner from '../../assets/panel-img/panel-img-becomepartner.jpg';
import hostEvent from '../../assets/panel-img/panel-img-hostevent.jpg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchStores } from '../../actions/stores';
import StoreCard from '../../components/store_card';
import PanelTile from '../../components/panel_tile';
const MAX_LIMIT = 24;

export default function FlowerShops(){
  const { hash } = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [activePage, setActivePage] = useState(hash.replaceAll("#", "") || 1)
  const stores = useSelector(state => state.stores)

  useEffect(() => {
    dispatch(fetchStores({
      page: activePage,
      per_page: MAX_LIMIT,
      sort: 'featured_at:desc',
      "filter[store_type]": 'flowers'
    }, () => {
      setLoading(false)
    }))
  }, [activePage])

  const sortByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const sorter = (a, b) => {
    // Featured should be at the top
    if (a.featured_at && !b.featured_at) {
      return -1;
    }
    if (!a.featured_at && b.featured_at) {
      return 1;
    }
    // Sort by name
    return sortByName(a, b);
  }

  return(

    <Content>
      <div className="results-content-container">
        <div className="top-search-container display-none">
          <View justify='center'>
            <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} xs={24} className='max-1120'>
              <Form className='mp-searcharea-wrap' fluid>
                <View>
                  <View.Item as={Col} lg={20} md={20} sm={24} xs={24} className='m-b-20'>
                    <Form.Group controlId={'search-1'}>
                      <InputGroup inside>
                        <InputGroup.Addon>
                          <FontAwesomeIcon icon={faLocationDot}/>
                          {/* <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: 80, marginBlock: 30, color: '#81C784' }}/> */}
                        </InputGroup.Addon>
                        <Form.Control className='mp-seach-field' placeholder='Search for area, street name, landmark...' name="search-1" type="text" autoComplete="off" />
                      </InputGroup>
                    </Form.Group>
                  </View.Item>
                  <View.Item as={Col} lg={4} md={4} sm={24} xs={24} className='m-b-20'>
                    <Button appearance="primary">Let&apos;s Go</Button>
                  </View.Item>
                </View>
              </Form>
            </View.Item>
          </View>
        </div>
        
        <View justify='center' className="stack-media-wrapper m-t-50">
          <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} xs={24} className='max-1120'>
            <h4 className='tile-heading'>Explore Our Flower Shops</h4>
            <View>
              {loading && <View.Item colspan={24} lg={24} justify="center">
                <Loader backdrop center content="Loading Stores..." size="md"/>
              </View.Item>}
              {Object.values(stores.byId).sort(sorter).map((store, id) => (<StoreCard key={id} store={store}/>))}
            </View>
            {!loading && <Divider/>}
            {stores.meta?.total > MAX_LIMIT &&<View justify='center'>
              <View.Item as={Col} colspan={24}>
                <Pagination
                  prev
                  next
                  size="md"
                  total={stores.meta.total}
                  limit={MAX_LIMIT}
                  activePage={parseInt(activePage)}
                  onChangePage={(page) => {
                    setActivePage(page)
                    navigate(`#${page}`)
                  }}
                />
              </View.Item>
            </View>}
          </View.Item>
        </View>
        
        {import.meta.env.VITE_APP_SLUG === "barkadamarket" && <View justify='center' style={{ paddingBottom: 50 }}>
          <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} xs={24} className='max-1120'>
            <div className='panel-tile-heading'>
              <h5>Join Us</h5>
              <p>Be a part of the Barkada Market story</p>
            </div>
            <div className='panel-tile-container'>
              <Grid fluid>
                <Row gutter={20}>
                  <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                    <PanelTile
                      href={`${import.meta.env.VITE_APP_VENDOR_URL}/setup`}
                      img={partner}
                      title='Become our partner'
                      description='Reach more customer and achieve growth with Barkada Market'
                      linkText="Learn More"
                    />
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                    <PanelTile
                      img={hostEvent}
                      title='Host your events'
                      description='Coming Soon'
                    />
                  </Col>
                </Row>
              </Grid>
            </div>
          </View.Item>
        </View>}
      </div>
    </Content>
  );
}