import { FlexboxGrid as View, Content, Col, Stack, Panel, Grid, Row } from 'rsuite';
import Cover from '../../../components/bm_cover.jsx';
import '../../../assets/bmhome.scss'
import food from '../../../assets/panel-img/panel-img-food.jpg';
import events from '../../../assets/panel-img/panel-img-events.jpg';
import services from '../../../assets/panel-img/panel-img-services.jpg';
import shop from '../../../assets/panel-img/panel-img-shop.jpg';
import partner from '../../../assets/panel-img/panel-img-becomepartner.jpg';
import hostEvent from '../../../assets/panel-img/panel-img-hostevent.jpg';
import { Link } from 'react-router-dom';
import PanelTile from '../../../components/panel_tile.jsx';

export default function BarkadaMarketHome(){

  return (
    
    <Content>
      {/* <div className='mp-bg'></div> */}
      <div className="floating-img-bg"></div>
      <Cover alt="cover"/>
      <View justify='center' style={{ marginBottom: 80 }}>
        <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} className='max-1120'>
          <div className='panel-tile-heading'>
            <h3>Your everyday, right away</h3>
            <p>Order food and grocery delivery online from hundreds of restaurants and shops nearby.</p>
          </div>
          <div className='panel-tile-container'>
            <Grid fluid>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <PanelTile
                    href='/restaurants'
                    img={food}
                    title='Food'
                    description='Find deals, free delivery, and more from our curated restaurant partners.'
                    linkText="Explore"
                  />
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <PanelTile
                    href='/events'
                    img={events}
                    title='Events'
                    description='Enhance your event and ensure its success with our robust management features.'
                    linkText="Explore"
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <PanelTile
                    img={services}
                    title='Services'
                    description='Coming Soon'
                  />
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <PanelTile
                    img={shop}
                    title='Shop'
                    description='Coming Soon'
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </View.Item>
      </View>
      <View justify='center' style={{ paddingBottom: 50 }}>
        <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} className='max-1120'>
          <div className='panel-tile-heading'>
            <h3>Join Us</h3>
            <p>Be a part of the Barkada Market story</p>
          </div>
          <div className='panel-tile-container'>
            <Grid fluid>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <PanelTile
                    href={`${import.meta.env.VITE_APP_VENDOR_URL}/setup`}
                    img={partner}
                    title='Become our partner'
                    description='Reach more customer and achieve growth with Barkada Market'
                    linkText="Learn More"
                  />
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <PanelTile
                    img={hostEvent}
                    title='Host your events'
                    description='Coming Soon'
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </View.Item>
      </View>
    </Content>
  );
}