'use client';

import { Divider, Content, Col, FlexboxGrid as View, Loader, Panel, 
  Stack, Button, Message, DatePicker, Form, 
  ButtonGroup,
  InputPicker
} from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { useEffect, useState } from 'react';
import { useStore } from '../../context/store.context';
import Cover from '../../components/store_cover.jsx';
import ProductCard from '../../components/product_card';
import { fetchProducts } from '../../actions/products';
import ProductModal from '../../components/product_modal.jsx';
import '../../assets/storecover.scss'
import '../../assets/stack-media.scss'
import '../../assets/side-cart.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faReceipt, faImage, faLocationDot, faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isBefore } from 'rsuite/esm/utils/dateUtils.js';
import { useCart } from '../../context/cart.context.jsx';
import { searchPlaces } from '../../actions/places.jsx';
import { updateCart, updateItem } from '../../actions/carts.jsx';
import CartItem from '../../components/cart_item.jsx';
import { formatOpeningHours, formatTime, isStoreOpen } from '../../utils/dates.jsx';
import { FULLFILLMENT_TYPES } from '../../constants/data.jsx';
import { useSettings } from '../../context/settings.context.jsx';

export default function MerchantHome({ slug, view=null }){
  const { settings } = useSettings();
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cart, cartIds } = useCart()
  const [addressQuery, setAddressQuery] = useState('')
  const [places, setPlaces] = useState([])
  const [deliveryAddressFieldShown, showDeliveryAddressField] = useState(true)
  const [loadingPlaces, setLoadingPlaces] = useState(false)
  const [loadingStore, setLoadingStore] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const { store, getStore } = useStore()
  const [address, setAddress] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [activeKey, setActiveKey] = useState('Pick-up')
  const [productModalShown, showProductModal] = useState(false)
  const [updatingQuantity, setUpdatingQuantity] = useState(false)
  const products = useSelector(state => state.products)
  const [outOfCoverage, setOutOfCoverage] = useState(false)
  const [noDeliveryAddressShown, showNoDeliveryAddress] = useState(false)
  const [noDeliveryDateShown, showNoDeliveryDate] = useState(false)
  console.log(settings.COUNTRIES)
  useEffect(() => {
    if (import.meta.env.VITE_API_BASE_URL === 'marketplace') {
      setLoadingStore(true)
      getStore(slug, () => {
        setLoadingStore(false)
      })
    }
  }, [])

  useEffect(() => {
    if (cart?.fulfillment_type === "pickup") {
      setActiveKey("Pick-up")
    } else {
      setActiveKey("Deliver")
    }
    showDeliveryAddressField(!cart?.shipping_address)
  }, [cart])

  useEffect(() => {
    if (addressQuery.length > 3) {
      const timer = setTimeout(async () => {
        setLoadingPlaces(true)
        searchPlaces(settings.COUNTRIES, addressQuery, (results) => {
          if (results) {
            setPlaces(results.map(place => place.placePrediction))
          }
          setLoadingPlaces(false)
        })
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [addressQuery])

  useEffect(() => {
    console.log("store loaded", store, loaded)
    if (store && !loaded) {
      setLoadingProducts(true)
      const params = {}
        params["filter[store_id]"] = store.id
        params["includes"] = "stocks,variants,taxons,product_properties,options"
        params["per_page"] = 'all'
        dispatch(fetchProducts(params, (response, error) => {
          if (response?.data?.products?.meta?.total === 0) {
            setEmpty(true)
          }
          setLoadingProducts(false)
          setLoaded(true)
        }))
    }
    if (store) {
      if (store?.address) setAddress(store.address)
      else if(!!store?.stock_locations?.length) {
        const location = store.stock_locations[0]
        if (location) {
          const {
            name,address1,address2,city,state_name,zipcode
          } = location
  
          let addressComponents = [address1, address2, city, state_name, zipcode];
          let addr = addressComponents.filter(Boolean).join(" ");
          setAddress([name, addr].filter(Boolean).join(" - "))
        }
      }
    }
  }, [store, loaded])

  const updateQuantity = (item, quantity) => {
    setUpdatingQuantity(item)
    dispatch(updateItem(cart.id, {
      variant_id: item.variant_id,
      quantity,
      store_id: cart.store.id
    }, () => {
      setUpdatingQuantity(false)
    }))
  }

  const onUpdate = (params, callback = () => {}) => {
    if (cart) {
      dispatch(updateCart(cart.id, params, callback))
    }
  }

  const onProceedToCheckout = () => {
    if (cart.fulfillment_type === "delivery") {
      showNoDeliveryAddress(!cart.shipping_address?.place_id)
      showNoDeliveryDate(!cart.delivery_date)
      navigate(`/authenticate/${cart?.id}`)
    }
  }

  return (
    <Content>
      {!!store?.cover && <Cover alt="cover"/>}
      <View className='merchant-cover-info xs-xspace-5' justify='center'>
        <View.Item as={Col} colspan={24} lg={17} md={22} sm={24} className='max-1120'>
          <View align='bottom'>
            <View.Item as={Col} colspan={24} lg={12} md={14} sm={24}>
              <h1 className='merchant-name'>{store?.name}</h1>
            </View.Item>
            <View.Item as={Col} colspan={24} lg={12} md={10} sm={24}>
              <div className='merchant-details'>
                {address && <p>{address}</p>}
                {/* <p>Burgers, Italian, American</p> */}
                {!!store?.opening_hours?.hours?.length && <p>
                  <span>Business Hours: </span>
                  <span>{formatOpeningHours(store?.opening_hours?.hours)}</span>
                </p>}
                <p>
                  {store?.cut_off_time && <span>
                    Cut-off time: {formatTime(store.cut_off_time, store.timezone)}
                  </span>}
                  <Divider vertical/>
                  {!!store?.opening_hours?.hours?.length && <span>
                    Store is {isStoreOpen(store?.opening_hours?.hours) ? <span className='badge badge-bg-success'>Open</span> : <span className='badge badge-bg-light'>Closed</span>}
                  </span>}
                </p>
              </div>
            </View.Item>
          </View>
          {store && <Divider className='m-t-15'/>}
        </View.Item>
      </View>
      <View justify='center'>
        {(loadingStore) && <Loader backdrop content="loading..." vertical />}
        <View.Item as={Col} colspan={24} lg={17} md={22} sm={24} style={{maxWidth: 1120}}>
          <View justify='center'>
            {/* <View.Item as={Col} colspan={24} lg={5}>
              <div className="cat-sidebar">
                <h6 className='fw-700' style={{ marginBottom: 10, fontSize: 12 }}>CATEGORIES</h6>  
                <Nav vertical>
                  <Nav.Item eventKey="home">American</Nav.Item>
                  <Nav.Item eventKey="home">Asian</Nav.Item>
                  <Nav.Item eventKey="home">BBQ</Nav.Item>
                  <Nav.Item eventKey="home">Bowls</Nav.Item>
                  <Nav.Item eventKey="home">British</Nav.Item>
                  <Nav.Item eventKey="home">Chinese</Nav.Item>
                </Nav>
              </div>
            </View.Item> */}
            <View.Item as={Col} colspan={24} lg={13}>
              <View justify='start' className='stack-media-wrapper fluid'>
              {loaded && products.keys.length === 0 && 
                <View.Item as={Col} colspan={24}>
                  <h6>No products found</h6>
                </View.Item>
              }
              {view === 'list' && store && Object.values(products.byId)
                .filter(product => product.stores[0].id === store?.id)
                .map((product, index) => (
                <View.Item key={index} as={Col} colspan={24} lg={24} className='tile-item'>
                  <Panel bordered bodyFill>
                    <Link
                      onClick={(e) => {
                        e.preventDefault()
                        showProductModal(product)
                      }}
                    >
                      <Stack justifyContent='flex-start' alignItems='stretch'>
                        <Stack.Item className='tile-item-img-sm'>
                          {!!product?.primary_variant?.images && <img
                            src={product?.primary_variant?.images[0]?.styles?.medium}
                            width={100}
                            height={100}
                            style={{ width: 100, height: 100}}
                            alt="product"
                          />}
                          {!product?.primary_variant?.images && 
                            <span className='empty-img'><FontAwesomeIcon icon={faImage}/></span>
                          }
                        </Stack.Item>
                        <Stack.Item grow={1}>
                          <div className='panel-tile-desc'>
                            <h6 className='stack-media-title'>
                              {product.name}
                            </h6>
                            {/* <p>
                              <small>Burgers, Italian, American</small>
                            </p> */}
                            <div className='tile-item-properties'>
                              {product?.product_properties?.filter(prop => prop.value).map((prop, index) => (
                                <small key={index} className='m-r-5'>
                                  {prop.name.replace( /([A-Z])/g, " $1" ).replaceAll("_", " ")}: {prop.value},
                                </small>
                              ))}
                            </div>
                          </div>
                        </Stack.Item>
                        <Stack.Item>
                          <div className="panel-action align-right">
                            <p>
                            {`${parseFloat(product.price).toLocaleString(undefined, {style:"currency", currency: product.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                            </p>
                            <span className='add-cart-action'><FontAwesomeIcon icon={faCirclePlus} /></span>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </Link>
                  </Panel>
                </View.Item>
              ))}
              {(loadingProducts && Object.values(products.byId)
                .filter(product => product.stores[0].id === store?.id).length === 0) && <Loader backdrop content="loading..."/>}
                {view !== 'list' && store && Object.values(products.byId)
                  .filter(product => product.stores[0].id === store?.id)
                  .map((product, index) => (
                  <View.Item key={index} as={Col} colspan={8} lg={6} md={12} sm={24} xs={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <ProductCard
                      product={product}
                      onClick={() => showProductModal(product)}
                    />
                  </View.Item>
                ))}
            </View>
            </View.Item>
            <View.Item className='sm-cart-container' as={Col} colspan={24} lg={8}>
              {cart && <Panel className='sm-cart-wrapper' bordered bodyFill>
                <div className="sm-cart-header">
                  <h6>
                    <FontAwesomeIcon icon={faLocationDot} />
                    Address
                  </h6>
                </div>
                <div className="sm-cart-list">
                  <div className='sm-cart-item'>
                    <ButtonGroup justified style={{marginBottom: 10}}>
                      {['Deliver', 'Pick-up'].map(key => (
                        <Button key={key} active={key === activeKey} onClick={() => {
                          onUpdate({fulfillment_type: FULLFILLMENT_TYPES[key]})
                          setActiveKey(key)
                        }}>
                          {key}
                        </Button>
                      ))}
                    </ButtonGroup>
                    
                    {activeKey === "Deliver" && <Form fluid>
                      <Form.Group controlId="delivery_date" className='m-b-10'>
                        <DatePicker
                          shouldDisableDate={date => isBefore(date, new Date().setHours(0, 0, 0, 0)) }
                          format="MM/dd/yyyy"
                          showMeridian
                          placeholder="Select Delivery Date"
                          block
                          defaultValue={cart?.delivery_date ? new Date(cart?.delivery_date) : null}
                          onChange={(date) => {
                            onUpdate({delivery_date: date})
                            showNoDeliveryDate(!date)
                          }}
                          style={{paddingBottom: 0}}
                        />
                        {noDeliveryDateShown && 
                          <Form.HelpText style={{color: 'red'}}>
                            Please select a delivery date
                          </Form.HelpText>
                        }
                      </Form.Group>
                      <Form.Group controlId="address">
                        {outOfCoverage && <Message type="warning" className='sm-cart-prompt-msg m-b-10'>
                          Sorry, this merchant doesn't deliver here.
                        </Message>}
                        {!deliveryAddressFieldShown && <div>
                          <p>
                            {cart?.shipping_address?.address_text} - <a href="#" onClick={(e) => {
                              e.preventDefault()
                              setOutOfCoverage(false)
                              showDeliveryAddressField(true)
                            }
                            }>Change</a>
                          </p>
                        </div>}
                        {deliveryAddressFieldShown && <Form.Control
                          accepter={InputPicker}
                          autoFocus
                          cleanable
                          name="address1"
                          data={places.map(place => ({label: place.text.text, value: place.placeId}))}
                          placeholder="ex. 123 Main St."
                          block
                          onClean={() => {
                            showDeliveryAddressField(false)
                            showNoDeliveryAddress(true)
                          }}
                          renderMenu={menu => {
                            if (loadingPlaces) {
                              return (
                                <p style={{ padding: 10, textAlign: 'center' }}>
                                  <SpinnerIcon spin /> Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                          onSelect={(place_id, {label}) => {
                            setOutOfCoverage(false)
                            onUpdate({place: { id: place_id, text: label }}, (response, error) => {
                              if (error?.response?.status === 422) {
                                setOutOfCoverage(true)
                              }
                              if (response) {
                                setOutOfCoverage(false)
                              }
                            })
                            showNoDeliveryAddress(false)
                          }}
                          menuClassName='mp-auto-complete'
                          valueKey="value"
                          labelKey="label"
                          onSearch={setAddressQuery}
                        />}
                        {noDeliveryAddressShown && 
                          <Form.HelpText style={{color: 'red'}}>Please enter a delivery location with street number</Form.HelpText>
                        }
                      </Form.Group>
                    </Form>}
                    {activeKey === "Pick-up" && <div>
                      <DatePicker
                        shouldDisableDate={date => isBefore(date, new Date().setHours(0, 0, 0, 0)) }
                        className='m-b-5'
                        format="MM/dd/yyyy"
                        placeholder="Select Pickup Date"
                        block
                        showMeridian
                        defaultValue={cart?.pickup_date ? new Date(cart?.pickup_date) : null}
                        onChange={(date) => onUpdate({pickup_date: date})}
                      />
                      <Form.HelpText>Please pick up your order at the store in the address below:</Form.HelpText>
                      <p style={{paddingTop: 10, paddingBottom: 10, fontWeight: 'bold'}}>{address}</p>
                    </div>}
                  </div>
                </div>
              </Panel>}
              <Panel className='sm-cart-wrapper' bordered bodyFill>
                <div className="sm-cart-header">
                  <h6>
                    <FontAwesomeIcon icon={faBagShopping} />
                    Your Shopping Cart
                  </h6>
                </div>
                <div className="sm-cart-list">
                  {!cart && <div className='sm-cart-item'>
                    <p>There are no items in your cart</p>
                  </div>}
                  {cart?.line_items?.slice()?.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at)))?.map((item, index) => (
                    <CartItem
                      item={item}
                      key={index}
                      compact
                      updating={updatingQuantity}
                      onUpdateQuantity={updateQuantity}
                    />
                  ))}
                </div>
              </Panel>
              {cart && <Panel className='sm-cart-wrapper' bordered bodyFill>
                <div className="sm-cart-header">
                  <h6>
                    <FontAwesomeIcon icon={faReceipt} />
                    Summary
                  </h6>
                </div>
                <div className="sm-cart-list sm-cart-summary">
                  <View className='sm-cart-item'>
                    <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                      <p>Subtotal</p>
                    </View.Item>
                    <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                      <p className='align-right'>
                      {!cart ? 0 : `${parseFloat(cart?.item_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                    </p>
                    </View.Item>
                  </View>
                  {cart?.fulfillment_type === "delivery" && <View className='sm-cart-item'>
                    <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                      <p>Delivery</p>
                    </View.Item>
                    <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                      <p className='align-right'>
                        {!cart ? 0 : `${parseFloat(cart?.display_shipment_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                      </p>
                    </View.Item>
                  </View>}
                  {settings.SERVICE_FEE_EXCLUSIVE === "true" && <View className='sm-cart-item'>
                    <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                      <p>Convenience Fee</p>
                    </View.Item>
                    <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                      <p className='align-right'>
                        {!cart ? 0 : `${parseFloat(cart?.display_service_fee).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                      </p>
                    </View.Item>
                  </View>}
                  <View className='sm-cart-item'>
                    <View.Item as={Col} colspan={24} className='m-b-5'><Divider className='m-t-5 m-b-5'/></View.Item>
                    <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                      <h6>TOTAL AMOUNT</h6>
                    </View.Item>
                    <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                      <h6 className='align-right'>
                      {!cart ? 0 : `${parseFloat(cart?.display_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                    </h6>
                    </View.Item>
                  </View>
                  <View className='sm-cart-item'>
                    <View.Item as={Col} colspan={24} >
                      <Button
                        appearance="primary"
                        block
                        disabled={!cart}
                        onClick={onProceedToCheckout}
                      >
                        Proceed to Checkout
                      </Button>
                    </View.Item>
                  </View>
                  
                </div>
              </Panel>}
            </View.Item>
          </View>
        </View.Item>
      </View>

      <ProductModal
        open={!!productModalShown}
        handleClose={() => showProductModal(false)}
        product={productModalShown}
        cartCount={20}
      /> 
    </Content>
  );
}